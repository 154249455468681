import { Form, FormQuestion } from 'redux/types/account';
import { getAxiosInstance } from './helper';
import { AxiosResponse } from 'axios';

import { prepareFormSaveStep1, prepareFormSaveStep2 } from 'components/forms/utils';

const ai = getAxiosInstance();

export const _mapResponseToForm = (form: any): Form => {
  return {
    id: form.id,
    name: form.name || '',
    description: form.description || '',
    communityId: form.communityId,
    creationDate: form.creationDate || '',
    lastEditionDate: form.lastEditionDate || '',
    userCreatorId: form.userCreatorId,
    includeInLandingPage: form.includeInLandingPage,
    questions: form.questions
      .sort((a: any, b: any) => a.questionOrder - b.questionOrder)
      .map(
        (question: any): FormQuestion => {
          let formResp = {
            id: question.id,
            title: question.title || '',
            question: question.question || question.title || '',
            description: question.description || '',
            questionOrder: question.questionOrder || 0,
            mandatoryAnswer: question.mandatoryAnswer,
            privacyLevel: question.privacyLevel,
            privacyCirclesId: question.privacyCirclesId || [],
            privacyCirclesIds: question.privacyCirclesIds || [],
            isFilterable: question.isFilterable || false,
            answerType: question.answerType,
            answerSubtype: '',
            allowMultipleAnswers: question.allowMultipleAnswers,
            questionOptions: question.questionOptions.sort(
              (a: any, b: any) => a.questionOptionOrder - b.questionOptionOrder,
            ),
          } as FormQuestion;

          if (question?.conditionalLogicType) {
            formResp = {
              ...formResp,
              conditionalLogic: !!question?.formQuestionConditionalLogicList?.length,
              conditionalLogicType: question?.conditionalLogicType,
              formQuestionConditionalLogicList: question?.formQuestionConditionalLogicList || [],
            };
          }

          return formResp;
        },
      ),
  };
};

export async function saveForm(
  bearer: string,
  communityId: number,
  form: Form,
): Promise<{ communityId: number; form: Form }> {
  const initialForm = { ...form };
  const formStep1 = prepareFormSaveStep1(initialForm);

  return new Promise((resolve, reject) => {
    ai({
      method: form.id ? 'PUT' : 'POST',
      url: `/api/ApplicationForm${form.id ? `/${form.id}` : ''}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      data: formStep1,
    })
      .then(({ data }) => {
        const formStep2 = prepareFormSaveStep2(initialForm, data);

        ai({
          method: 'PUT',
          url: `/api/ApplicationForm/${formStep2.id}`,
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
          data: formStep2,
        }).then((response: AxiosResponse) => {
          resolve({
            communityId,
            form: _mapResponseToForm(response.data),
          });
        });
      })
      .catch(reject);
  });
}

export function fetchCommunityForms(
  bearer: string,
  communityId: number,
): Promise<{ communityId: number; forms: Form[] }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/Community/${communityId}/Forms`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          forms: response.data.list.map(_mapResponseToForm),
        });
      })
      .catch(err => reject(err));
  });
}

export function deleteForm(
  bearer: string,
  communityId: number,
  formId: number,
): Promise<{ communityId: number; formId: number }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'DELETE',
      url: `/api/ApplicationForm/${formId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(() => {
        resolve({
          communityId,
          formId,
        });
      })
      .catch(err => reject(err));
  });
}

export function fetchFormById(
  bearer: string,
  communityId: number,
  formId: number,
): Promise<{ communityId: number; form: Form }> {
  return new Promise((resolve, reject) => {
    ai({
      method: 'GET',
      url: `/api/ApplicationForm/${formId}`,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then((response: AxiosResponse) => {
        resolve({
          communityId,
          form: _mapResponseToForm(response.data),
        });
      })
      .catch(err => reject(err));
  });
}

export default {
  saveForm,
  fetchCommunityForms,
  deleteForm,
  fetchFormById,
};
