import React, { useState } from 'react';

const useFileSelection = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const openFileSelection = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpeg, image/png, image/webp'; // Limit to JPEG, PNG and WebP files only
    input.onchange = e => handleFileChange((e as unknown) as React.ChangeEvent<HTMLInputElement>);
    input.click();
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
  };

  return { selectedFile, openFileSelection, clearSelectedFile };
};

export default useFileSelection;
