import React, { useState, useEffect, SyntheticEvent, ChangeEvent, useMemo } from 'react';
import styled from 'styled-components';
import { RootState } from 'StoreModel';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { map, append, filter } from 'ramda';
import {
  DropdownProps,
  Dimmer,
  Loader,
  Search,
  Label as SelectedSkill,
  Icon,
  Image as ProfilePhoto,
  Input,
} from 'semantic-ui-react';
import { AspectRatio, Flag } from '../../redux/types/enums';
import { useHistory } from 'react-router-dom';
import { debounce } from 'debounce';

import { toast } from '../../components/common/toast';
import { GridModal } from '../../components/common/use-grid-modal';
import BioEditor from '../../components/account/ckeditor-bio';
import {
  NoBackgroundButton,
  SecondaryButton,
  ColumnStart,
  Column,
  SDGCard,
  SGDContainer,
} from '../../components/styled-components/common';
import { fetchStandardInterestList } from '../../services/api/account';
import { fetchUserProfileAsync } from '../../redux/actions/users';
import { saveProfileAsync, fetchSkillsAsync, fetchCountriesAsync, fetchSDGsAsync } from '../../redux/actions/account';
import { fetchCommunityDetailsAsync } from 'redux/actions/community';
import { Skill, SkillCategory, Interest, SDG, Country } from 'redux/types/account';
import { removeMultipleNewLines } from '../../util/utils';
import defaultProfilePicture from '../../assets/common/profile-placeholder.svg';
import { Dropdown } from '../../components/common/dropdown';
import { filterSkillCategories } from 'util/skillCategoriesFilter';
import { Grid } from '@material-ui/core';
import { CalendarSyncWizardOptionButton } from '../../components/styled-components/user-profile-calendar-components';
import GoogleCalendarLogo from '../../assets/logos/google-calendar-logo.png';
import OutlookLogo from '../../assets/logos/outlook-logo.png';
import { getUrlWithRedirectUri, URLType } from '../../util/calendar';
import ImageCropper from 'components/common/image-crop/crop-image';
import useFileSelection from 'components/common/image-crop/FileSelection';

const CategoryImg = styled.img`
  width: 7em;
  height: 7em;
  margin: 0.2em;
  border-radius: 5px;
  cursor: pointer;
`;

const StyledSearch = styled(Search)`
  &&& {
    .visible {
      max-height: 25em;
      overflow: auto;
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 5em 0;

  h2 {
    text-align: center;
    width: 100%;
  }
`;

const Form = styled.form<{ $step: number }>`
  display: flex;
  width: 40em;
  ${({ $step }) => $step !== 2 && `height: 100%;`}
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

const HFContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const Label = styled.span`
  font-weight: 600;
  font-size: 0.9em;
  margin: 1em 0 0.5em 0;
`;

const SourceInput = styled.input`
  &&& {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

const Description = styled.span`
  font-size: 0.9em;
  margin: 1em 0;
  padding: 0 3em;
  text-align: center;
  width: 100%;
`;

export const StyledInput = styled.input<{ $centerPlaceholder?: boolean }>`
  box-sizing: border-box;

  background: ${props => props.theme.colors.blue.veryLight};
  padding: 0.43em;
  border-radius: 0.2em;
  border: 2px solid ${props => props.theme.colors.blue.veryLight};
  :focus {
    background: white;
    border: 2px solid ${props => props.theme.colors.blue.normal};
  }
`;

const ContinueButton = styled(NoBackgroundButton)`
  * {
    color: ${({ theme }) => theme.colors.blue.normal} !important;
  }
`;

const InterestsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  overflow: scroll;
`;

const SkillsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding-bottom: 2em;
`;

const SkillCategoriesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const WizardSyncCalendarContainer = styled.div`
  padding: 2rem 1rem;
  width: 100%;
`;

const SkillInterestLabel = styled.label`
  display: flex;
  align-items: center;
  width: 20em;

  span {
    font-size: 0.9em;
  }

  input {
    margin-right: 1em;
  }
`;

const SkillContainer = styled.div`
  margin: 0.2em;
`;

const UploadPhotoLabel = styled(Label)`
  cursor: pointer;
`;

const mapDispatchToProps = {
  saveProfile: saveProfileAsync.request,
  fetchSkills: fetchSkillsAsync.request,
  fetchUserProfile: fetchUserProfileAsync.request,
  fetchCountries: fetchCountriesAsync.request,
  fetchCommunity: fetchCommunityDetailsAsync.request,
  fetchSDGs: fetchSDGsAsync.request,
};

const mapStateToProps = (state: RootState) => ({
  account: state.account.details.user,
  bearer: state.account.session.session.bearer,
  primaryCommunity: state.account.selectedCommunity,
  fetchSkillsFlag: state.loading.fetchSkillsFlag,
  fetchUserProfileFlag: state.loading.fetchUserProfileFlag,
  skillsCategories: state.account.skillsCategories,
  literals: state.literals,
  countries: state.countries,
  fetchCountriesFlag: state.loading.fetchCountriesFlag,
  sdgs: state.sdgs,
  fetchSDGsFlag: state.loading.fetchSDGsFlag,
});

type SearchResultData = {
  id: number;
  title: string;
};

interface CountryListItem {
  key: number;
  value: number;
  text: string;
}

type dispatchType = typeof mapDispatchToProps;
interface Props extends ReturnType<typeof mapStateToProps>, dispatchType {}

const EditProfile: React.FC<Props> = ({
  account,
  primaryCommunity,
  bearer,
  saveProfile,
  fetchSkills,
  fetchSkillsFlag,
  skillsCategories,
  literals,
  fetchUserProfileFlag,
  fetchUserProfile,
  countries,
  fetchCountriesFlag,
  fetchCountries,
  sdgs,
  fetchSDGsFlag,
  fetchSDGs,
  fetchCommunity,
}) => {
  const history = useHistory();
  const countriesOptions: CountryListItem[] = map(c => ({ key: c.id, value: c.id, text: c.name }), countries);
  const SDGs = sdgs;

  const [isFetching, setIsFetching] = useState(true);
  const [savingProfile, setSavingProfile] = useState(false);
  const [interestsList, setInterestsList] = useState<any[]>([]);
  const [skillsList, setSkillsList] = useState<{ [key: string]: Skill }>({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchSkillsResults, setSearchSkillsResults] = useState<SearchResultData[]>([]);
  const [searchMarketsResults, setSearchMarketsResults] = useState<SearchResultData[]>([]);

  const [step, setStep] = useState((history.location.state as any)?.editProfileStartStep || 1);
  const [country, setCountry] = useState(account.countryId || (countries && countries[0] && countries[0].id) || 2);
  const [city, setCity] = useState(account.city || '');
  const [role, setRole] = useState(account.occupation || '');
  const [linkedin, setLinkedin] = useState<string | undefined>(undefined);
  const [company, setCompany] = useState(account.company || '');
  const [bio, setBio] = useState(account.profile ? account.profile.biography : '');
  const [photo, setPhoto] = useState(account.photo);
  const [name, setName] = useState({ firstName: account.firstName, lastName: account.lastName });
  const [file, setFile] = useState<File | null>(null);
  const [categorySkillModalIsOpen, setCategorySkillModalIsOpen] = useState<boolean>(false);
  const [categorySkills, setCategorySkills] = useState<Array<Skill>>([]);
  const [editSkillsModalOptions, setEditSkillsModalOptions] = useState<number[]>([]);
  const [categorySkillsModalTitle, setCategorySkillsModalTitle] = useState<string>('');
  const [selectedSDGs, setSelectedSDGs] = useState<SDG[]>(
    account.profile ? account.profile.sustainableDevelopmentGoals : [],
  );

  const [selectedInterests, setSelectedInterests] = useState<number[]>(
    account.profile ? account.profile.interests.map((i: Interest) => i.id) : [],
  );
  const [selectedSkills, setSelectedSkills] = useState<number[]>(
    account.profile ? account.profile.skills.map((s: Skill): number => s.id) : [],
  );

  const convertCountryToSearchResult = (country: Country): SearchResultData => ({
    id: country.id,
    title: country.name,
  });
  const [selectedMarkets, setSelectedMarkets] = useState<Country[]>(
    account.profile ? account.profile.marketExpertises : [],
  );

  const { selectedFile, openFileSelection, clearSelectedFile } = useFileSelection();

  useEffect(() => {
    if (sdgs.length === 0 && fetchSDGsFlag !== Flag.Request) {
      fetchSDGs({ bearer });
    }
  }, []);

  useEffect(() => {
    if (countries.length === 0 && fetchCountriesFlag !== Flag.Request) {
      fetchCountries({ bearer });
    }
  }, []);

  useEffect(() => {
    const skills: { [key: string]: Skill } = {};
    filteredSkillCategories.forEach((category: SkillCategory) => {
      if (category.skills) {
        category.skills
          .filter((skill: Skill) => skill.isActive)
          .forEach((skill: Skill) => {
            skills[skill.id] = skill;
          });
      }
    });
    setSkillsList(skills);
  }, [primaryCommunity, skillsCategories]);

  useEffect(() => {
    if (account.primaryCommunityId) {
      fetchCommunity({
        id: account.primaryCommunityId,
        bearer,
        silentCall: true,
      });
    }
  }, [account.primaryCommunityId]);

  useEffect(() => {
    if (skillsCategories.length === 0 && fetchSkillsFlag !== Flag.Request) {
      fetchSkills({ bearer });
    }
  }, []);

  const filteredSkillCategories = useMemo(() => filterSkillCategories(skillsCategories, primaryCommunity) || [], [
    primaryCommunity,
    skillsCategories,
  ]);

  useEffect(() => {
    if (account.linkedInUrl === undefined) {
      fetchUserProfile({ bearer, id: account.id });
    }

    fetchStandardInterestList(bearer)
      .then(response => {
        setInterestsList(response);
      })
      .catch(() => {})
      .finally(() => setIsFetching(false));
  }, []);

  const redirectToAccount = () => {
    if (savingProfile) {
      history.push('/account');
    }
  };

  useEffect(redirectToAccount, [account]);

  useEffect(() => {
    if (account.linkedInUrl !== undefined) {
      setLinkedin(account.linkedInUrl);
    }
  }, [account]);

  const filterSkill = (skill: Skill): boolean => !!skill.name && skill.name.toLowerCase().includes(searchTerm);
  const convertSkillToSearchResult = (skill: Skill): SearchResultData => ({ id: skill.id, title: skill.name });

  const filterCountries = (country: Country): boolean =>
    !!country.name && country.name.toLowerCase().includes(searchTerm);

  const filterSkills = debounce(() => {
    const searchedSkills: SearchResultData[] = searchTerm
      ? Object.values(skillsList).filter(filterSkill).map(convertSkillToSearchResult)
      : [];
    setSearchSkillsResults(searchedSkills);
  }, 200);

  const filterMarkets = debounce(() => {
    const searchedMarkets: SearchResultData[] = searchTerm
      ? countries.filter(filterCountries).map(convertCountryToSearchResult)
      : [];

    setSearchMarketsResults(searchedMarkets);
  }, 200);

  useEffect(() => {
    filterSkills();
  }, [searchTerm, skillsList]);

  useEffect(() => {
    filterMarkets();
  }, [searchTerm]);

  const onBack = () => setStep(step - 1);

  const onCancel = () => history.push('/account');

  const onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    if (step === 3 && selectedInterests.length < 1) {
      toast(literals.user_settings_interests_minimum_length_message, { type: 'warning' });
      return;
    }
    if (step === 4 && selectedSkills.length < 1) {
      toast(literals.user_settings_skills_minimum_length_message, { type: 'warning' });
      return;
    }
    if (step === 1 && name.firstName && name.firstName.length === 0 && name.lastName && name.lastName.length === 0) {
      toast(literals.user_settings_name_message, { type: 'warning' });
      return;
    }

    if (step < 5) setStep(step + 1);
    else if (step < 6 && (primaryCommunity ? primaryCommunity.isUserSDGsVisible : false)) setStep(step + 1);
    else {
      setSavingProfile(true);
      saveProfile({
        bearer,
        userId: account.id,
        language: account.language || 'en-EN',
        rawUser: account.rawUser,
        country,
        city,
        role,
        bio: removeMultipleNewLines(bio),
        file,
        photo,
        firstName: name.firstName,
        lastName: name.lastName,
        interests: selectedInterests,
        skills: selectedSkills,
        marketExpertises: selectedMarkets,
        sustainableDevelopmentGoals: selectedSDGs,
        primaryCommunityId: account.primaryCommunityId,
        linkedInUrl: linkedin || '',
        company: company,
      });
    }
  };

  const handleSDGClick = (clickedSdg: SDG) => {
    const alreadyIn = selectedSDGs.find(s => s.id === clickedSdg.id);
    let SDGs: SDG[] = [];
    if (alreadyIn) {
      SDGs = filter(s => s.id !== clickedSdg.id, selectedSDGs);
    } else {
      SDGs = append(clickedSdg, selectedSDGs);
    }
    setSelectedSDGs(SDGs);
  };

  const handleImageUpload = (file: File) => {
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);

    if (file.size > 2097152) {
      toast(literals.user_settings_profile_picture_size_limit, { type: 'warning' });
      return;
    }

    reader.onload = (onLoadEvent: any) => {
      if (onLoadEvent && onLoadEvent.target && onLoadEvent.target.result) {
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          // if (img.naturalHeight === img.naturalWidth) {
          setPhoto(onLoadEvent.target.result);
          setFile(file);
          // } else {
          //   toast('At the moment we accept only square images.', { type: 'warning' });
          //   return;
          // }
        };
      }
    };
    reader.onerror = onErrorEvent => {
      if (onErrorEvent && onErrorEvent.target && onErrorEvent.target.error) {
        if (onErrorEvent.target.error.name === 'NotReadableError') {
          toast(literals.apply_to_team_project_service_on_project_applt_to_team_error, { type: 'error' });
        }
      }
    };
  };

  const onInterestChanged = (event: any) => {
    const target = event.target;
    if (target.checked) {
      const newInterests = append(parseInt(target.name), selectedInterests);
      setSelectedInterests(newInterests);
    } else {
      const newInterests = filter(i => i !== parseInt(target.name), selectedInterests);
      setSelectedInterests(newInterests);
    }
  };

  const onSearchSkill = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = event;
    const newSearchTerm: string = value ? value.toLowerCase() : '';
    setSearchTerm(newSearchTerm);
  };

  const onSearchMarkets = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = event;
    const newSearchTerm: string = value ? value.toLowerCase() : '';
    setSearchTerm(newSearchTerm);
  };

  const onSelectSkill = (e: SyntheticEvent | undefined, { result }: { [key: string]: Skill }): void => {
    if (!result) return;
    const newSelectedSkills: number[] = [...selectedSkills];
    if (!newSelectedSkills.includes(result.id)) {
      newSelectedSkills.push(result.id);
    }
    setSelectedSkills(newSelectedSkills);
    setSearchTerm('');
  };

  const onSelectMarket = (e: SyntheticEvent | undefined, { result }: { [key: string]: SearchResultData }): void => {
    if (!result) return;
    const countryResult = countries.find((c: Country) => c.id === result.id);
    if (!countryResult) return;
    const newSelectedMarket: Country[] = [...selectedMarkets];
    if (!newSelectedMarket.find((m: Country) => m.id === countryResult.id)) {
      newSelectedMarket.push(countryResult);
    }
    setSelectedMarkets(newSelectedMarket);
    setSearchTerm('');
  };

  const onRemoveSkill = (id: number): void => {
    const newSelectedSkills: number[] = selectedSkills.filter((skillId: number): boolean => skillId !== id);
    setSelectedSkills(newSelectedSkills);
  };

  const onRemoveMarket = (id: number): void => {
    const newSelectedMarkets: Country[] = selectedMarkets.filter((market: Country): boolean => market.id !== id);
    setSelectedMarkets(newSelectedMarkets);
  };

  const handleCategoryClick = (categoryName: string, categoriesWithSkills: Array<SkillCategory>) => {
    const skills = categoriesWithSkills.filter((c: SkillCategory) => c.name === categoryName)[0].skills;
    setCategorySkillModalIsOpen(true);
    setEditSkillsModalOptions(selectedSkills);
    setCategorySkills(skills);
    setCategorySkillsModalTitle(categoryName);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{literals.profile_wizard_short_complete_profile_message}</title>
      </Helmet>
      <Container>
        {(isFetching || fetchSkillsFlag === Flag.Request || fetchUserProfileFlag === Flag.Request) && (
          <Dimmer inverted active>
            <Loader inverted>{literals.project_list_loading_tag_message}</Loader>
          </Dimmer>
        )}
        <Form onSubmit={onSubmit} $step={step}>
          <HFContainer>
            {account.countryId ? (
              <NoBackgroundButton type="button" onClick={onCancel}>
                <span>{literals.confirm_cancel_button}</span>
                <i className="material-icons">close</i>
              </NoBackgroundButton>
            ) : (
              <span></span>
            )}
            <NoBackgroundButton style={{ visibility: step > 1 ? 'inherit' : 'hidden' }} type="button" onClick={onBack}>
              <i className="material-icons">arrow_back</i>
              <span>{literals.profile_wizard_02_back_button}</span>
            </NoBackgroundButton>
          </HFContainer>
          {step === 1 && (
            <>
              <h2>
                {literals.wizard_step_title.replace('{0}', step)} {literals.profile_wizard_01_title}
              </h2>
              <Row style={{ textAlign: 'left', marginBottom: '1em' }}>
                <div style={{ width: '50%', paddingRight: '1em' }}>
                  <Label>{literals.public_signup_firstname}</Label> <br />
                  <Input
                    placeholder={literals.public_signup_firstname}
                    value={name.firstName}
                    style={{ width: '100%' }}
                    onChange={evt => setName({ ...name, firstName: evt.target.value })}
                  />
                </div>
                <div style={{ width: '50%', paddingRight: '1em' }}>
                  <Label>{literals.public_signup_last_name_placeholder}</Label>
                  <br />
                  <Input
                    placeholder={literals.public_signup_last_name_placeholder}
                    value={name.lastName}
                    style={{ width: '100%' }}
                    onChange={evt => setName({ ...name, lastName: evt.target.value })}
                  />
                </div>
              </Row>
              <Row style={{ justifyContent: 'center' }}>
                <ProfilePhoto
                  src={photo || account.photo}
                  circular={true}
                  onError={(event: any) => (event.target.src = defaultProfilePicture)}
                  style={{ objectFit: 'cover', width: '10em', height: '10em' }}
                  alt={`${account.name}`}
                />
              </Row>
              <Row style={{ justifyContent: 'center' }}>
                <ImageCropper
                  open={!!selectedFile}
                  onClose={clearSelectedFile}
                  cropShape={AspectRatio.SQUARE}
                  handleFileSelection={handleImageUpload}
                  selectedFile={selectedFile}
                  openFileSelection={openFileSelection}
                  circularCrop
                />
                <UploadPhotoLabel as="label" htmlFor="profile-photo" onClick={openFileSelection}>
                  <SecondaryButton type="button" style={{ pointerEvents: 'none' }}>
                    {literals.user_settings_change_picture_button}
                  </SecondaryButton>
                </UploadPhotoLabel>
              </Row>
              <Description>{literals.profile_wizard_01_description.replace('{0}', account.name)} 🐑</Description>
            </>
          )}
          {step === 2 && (
            <>
              <h2>
                {literals.wizard_step_title.replace('{0}', step)} {literals.profile_wizard_02_title}
              </h2>
              <Description>{literals.profile_wizard_02_description}</Description>
              <Row>
                <ColumnStart style={{ width: '45%' }}>
                  <Label>{literals.profile_wizard_02_location_country}</Label>
                  <Dropdown
                    placeholder={literals.global_dropdown_select_country}
                    selection={true}
                    clearable={false}
                    options={countriesOptions}
                    required
                    onChange={(event: SyntheticEvent, data: DropdownProps) => setCountry(data.value as number)}
                    value={country}
                    search={true}
                  />
                </ColumnStart>
                <ColumnStart style={{ width: '45%' }}>
                  <Label>{literals.profile_wizard_02_location_city}</Label>
                  <StyledInput
                    style={{ width: '100%' }}
                    required
                    maxLength={30}
                    placeholder={literals.input_character_limit_under.replace('{0}', '30')}
                    value={city}
                    onChange={event => setCity(event.target.value)}
                  />
                </ColumnStart>
              </Row>
              <Row>
                <ColumnStart style={{ width: '45%' }}>
                  <Label>{literals.user_header_company_placeholder}:</Label>
                  <StyledInput
                    style={{ width: '100%' }}
                    required
                    maxLength={60}
                    placeholder={literals.input_character_limit_under.replace('{0}', '60')}
                    value={company}
                    onChange={event => setCompany(event.target.value)}
                  />
                </ColumnStart>
                <ColumnStart style={{ width: '45%' }}>
                  <Label>{literals.user_header_occupation_placeholder}:</Label>
                  <StyledInput
                    style={{ width: '100%' }}
                    required
                    maxLength={60}
                    placeholder={literals.input_character_limit_under.replace('{0}', '60')}
                    value={role}
                    onChange={event => setRole(event.target.value)}
                  />
                </ColumnStart>
              </Row>
              <Row>
                <ColumnStart style={{ width: '45%' }}>
                  <Label>{literals.create_project_06_connectivity_linkedin}:</Label>
                  <StyledInput
                    style={{ width: '100%' }}
                    maxLength={100}
                    placeholder={literals.input_character_limit_under.replace('{0}', '100')}
                    value={linkedin}
                    onChange={event => setLinkedin(event.target.value)}
                  />
                </ColumnStart>
              </Row>
              <Label>{literals.profile_wizard_02_bio_label}</Label>
              <Column style={{ width: '100%', flex: 1 }}>
                <BioEditor bio={bio} setBio={setBio} placeholder={literals.profile_wizard_02_bio_placeholder} />
              </Column>
            </>
          )}
          {step === 3 && (
            <>
              <h2>
                {literals.wizard_step_title.replace('{0}', step)} {literals.profile_wizard_short_service_stage_title_3}
              </h2>
              <Description>{literals.profile_wizard_03_description}</Description>
              <InterestsContainer>
                {interestsList.map(i => (
                  <SkillInterestLabel key={i.id}>
                    <input
                      type="checkbox"
                      name={i.id}
                      onChange={onInterestChanged}
                      checked={selectedInterests.includes(i.id)}
                    />
                    <span style={{ whiteSpace: 'nowrap' }}>{i.name}</span>
                  </SkillInterestLabel>
                ))}
              </InterestsContainer>
            </>
          )}
          {step === 4 && (
            <>
              <h2>
                {literals.wizard_step_title.replace('{0}', step)} {literals.profile_wizard_03_selected_skills_min_info}
              </h2>
              <Description>{literals.profile_wizard_04_title}</Description>
              <StyledSearch
                icon="search"
                results={searchSkillsResults}
                value={searchTerm}
                aligned="center"
                size="small"
                placeholder={`${literals.profile_wizard_04_search_skills_placeholder}...`}
                onSearchChange={onSearchSkill}
                onResultSelect={onSelectSkill}
                style={{ margin: '0 auto 2em' }}
              />
              <SkillsContainer>
                {selectedSkills.map((id: number) => {
                  if (skillsList[id]) {
                    const { name } = skillsList[id];
                    return (
                      <SkillContainer key={id}>
                        <SelectedSkill basic>
                          {name}
                          <Icon name="delete" onClick={() => onRemoveSkill(id)} />
                        </SelectedSkill>
                      </SkillContainer>
                    );
                  }
                  return null;
                })}
              </SkillsContainer>
              <SkillCategoriesContainer>
                {filteredSkillCategories.map((skillCategory: SkillCategory) => (
                  <CategoryImg
                    key={skillCategory.name}
                    src={skillCategory.img}
                    onClick={(e: SyntheticEvent) => {
                      e.preventDefault();
                      handleCategoryClick(skillCategory.name, skillsCategories);
                    }}
                    alt={skillCategory.name}
                  />
                ))}
                <GridModal
                  title={categorySkillsModalTitle}
                  data={categorySkills}
                  onItemClick={(skill: Skill) => {
                    setEditSkillsModalOptions([...editSkillsModalOptions, skill.id]);
                  }}
                  isOpen={categorySkillModalIsOpen}
                  saveModal={() => {
                    setSelectedSkills(editSkillsModalOptions);
                    setCategorySkillModalIsOpen(false);
                  }}
                  closeModal={() => setCategorySkillModalIsOpen(false)}
                  skillsList={skillsList}
                  selectedSkills={editSkillsModalOptions}
                  onRemoveSkill={(skillId: number) => {
                    setEditSkillsModalOptions(editSkillsModalOptions.filter((id: number) => id !== skillId));
                  }}
                />
              </SkillCategoriesContainer>
            </>
          )}
          {step === 5 && (
            <>
              <h2>
                {literals.wizard_step_title.replace('{0}', step)} {literals.profile_wizard_05_title}
              </h2>
              <Description>
                {literals.wizard_final_step_description} {literals.profile_wizard_05_description}
              </Description>
              <StyledSearch
                icon="search"
                results={searchMarketsResults}
                value={searchTerm}
                aligned="center"
                size="small"
                placeholder={`${literals.profile_wizard_05_search_skills_placeholder}...`}
                onSearchChange={onSearchMarkets}
                onResultSelect={onSelectMarket}
                style={{ margin: '0 auto 2em' }}
              />
              <SkillsContainer>
                {selectedMarkets.map((country: Country) => {
                  return (
                    <SkillContainer key={country.id}>
                      <SelectedSkill basic>
                        {country.name}
                        <Icon name="delete" onClick={() => onRemoveMarket(country.id)} />
                      </SelectedSkill>
                    </SkillContainer>
                  );
                })}
              </SkillsContainer>
            </>
          )}
          {step === 6 && (
            <>
              <h2>
                {literals.wizard_final_step_title}: {literals.profile_wizard_06_title}
              </h2>
              <Description>
                {literals.wizard_final_step_description} {literals.profile_wizard_06_description}
              </Description>
              <SGDContainer style={{ paddingTop: '1em' }}>
                {SDGs.map((sdg: SDG) => {
                  return (
                    <SDGCard
                      key={sdg.id}
                      src={sdg.iconName}
                      active={selectedSDGs.some(s => s.id === sdg.id)}
                      onClick={() => handleSDGClick(sdg)}
                    />
                  );
                })}
              </SGDContainer>
            </>
          )}
          {step === 7 && (
            <WizardSyncCalendarContainer>
              <h2 style={{ textAlign: 'center' }}>
                {literals.wizard_step_title.replace('{0}', step)} {literals.calendar_user_profile_sync_calendar}
              </h2>
              <p style={{ textAlign: 'center', marginBottom: '4em' }}>{literals.wizard_calendar_sync_desc}</p>
              <Grid container>
                <Grid item sm={6}>
                  <CalendarSyncWizardOptionButton href={getUrlWithRedirectUri('', URLType.GoogleCalendar)}>
                    <img
                      style={{ width: '7em', maxWidth: '8rem', objectFit: 'contain' }}
                      src={GoogleCalendarLogo}
                      alt="Google"
                    />
                  </CalendarSyncWizardOptionButton>
                </Grid>
                <Grid item sm={6}>
                  <CalendarSyncWizardOptionButton href={getUrlWithRedirectUri('', URLType.Outlook)}>
                    <img
                      style={{ width: '10em', maxWidth: '10rem', objectFit: 'contain' }}
                      src={OutlookLogo}
                      alt="Outlook"
                    />
                  </CalendarSyncWizardOptionButton>
                </Grid>
              </Grid>
            </WizardSyncCalendarContainer>
          )}
          <HFContainer>
            <ContinueButton type="submit" disabled={savingProfile}>
              {savingProfile ? (
                <span>{literals.profile_wizard_saving_profile}</span>
              ) : (
                <span>
                  {step < 5
                    ? literals.profile_wizard_short_next_button
                    : step < 6 && (primaryCommunity ? primaryCommunity.isUserSDGsVisible : false)
                    ? literals.profile_wizard_short_next_button
                    : literals.profile_wizard_save_profile}
                </span>
              )}
              <i className="material-icons">arrow_forward</i>
            </ContinueButton>
          </HFContainer>
        </Form>
      </Container>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
