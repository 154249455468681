import React, { useState, SyntheticEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import styled from 'styled-components';
import { User } from 'redux/types/account';
import { useLocation, useParams } from 'react-router-dom';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';

import { Separator, NoBackgroundButton } from '../styled-components/common';
import { downloadPersonalInfo, resetPassword, disableAccount, changeAccountEmail } from '../../services/api/account';
import { toast } from '../common/toast';
import Modal from '../common/modal-component';
import { CalendarProvider, Flag, Languages } from '../../redux/types/enums';
import NotificationsTab from '../../components/account/notifications-settings';
import { Dropdown } from '../common/dropdown';
import { StyledButton } from 'primitives/Button/style';
import Calendar from './calendar-settings';
import {
  extractMeetingInfoFromUrl,
  getLanguageOptions,
  trimCodeFromStringGoogle,
  trimCodeFromStringOutlook,
} from '../../util/utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 0.8em;
`;

const SectionName = styled.p<{ $open: boolean }>`
  line-height: 1em;
  font-weight: 600;
  margin: 0.5em 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  & > * {
    ${({ $open }) => !$open && `transform: rotate(-90deg)`}
  }
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 0.7em;
  margin: 0.5em 0;
  text-align: left;
  color: ${props => props.theme.colors.grey.dark};
  text-transform: uppercase;
`;

const Line = styled.p`
  margin: 0;
  line-height: 1.5em;
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 50%;

  background: ${props => props.theme.colors.blue.veryLight};
  padding: 0.43em;
  border-radius: 0.2em;
  border: 2px solid ${props => props.theme.colors.blue.veryLight};
  :focus {
    background: white;
    border: 2px solid ${props => props.theme.colors.blue.normal};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionButton = styled(NoBackgroundButton)`
  &&& {
    padding-left: 0;
    color: ${({ theme }) => theme.colors.blue.normal};
    text-transform: none;
  }
`;

const RedButton = styled(ActionButton)`
  &&& {
    color: ${({ theme }) => theme.colors.orange.normal};
  }
`;

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

interface Props extends ReturnType<typeof mapStateToProps> {
  account: User;
  bearer: string;
  signOut: () => void;
  changeLanguage: (language: Languages) => void;
  isFetching: Flag;
}

const Account: React.FC<Props> = ({ account, changeLanguage, bearer, signOut, isFetching, literals }) => {
  const { options } = useParams<{ options: string }>();
  const [isDownloading, setIsDownloading] = useState(false);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [oldEmail, setOldEmail] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const [newEmailConfirmation, setNewEmailConfirmation] = useState<string>('');
  const [emailPassword, setEmailPassword] = useState<string>('');
  const [isChangingEmail, setIsChangingEmail] = useState<boolean>(false);
  const [openSections, setOpenSections] = useState<{
    password: boolean;
    account: boolean;
    notifications: boolean;
    language: boolean;
    calendar: boolean;
    email: boolean;
  }>({
    password: false,
    account: false,
    notifications: false,
    language: false,
    calendar: false,
    email: false,
  });
  const [notificationsOptions, setNotificationsOptions] = useState<string>('');
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [languageOptions, setLanguageOptions] = useState<{ key: string; text: string; value: string }[]>([]);

  const [syncCalendarData, setSyncCalendarData] = useState<{ code: string; provider: 0 | 1 } | null>(null);
  const [syncMeetingProviderData, setSyncMeetingProviderData] = useState<{
    code: string;
    meetingProvider: 0 | 1;
  } | null>(null);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/provider')) return;
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (!code) return;

    if (location.search.includes('google')) {
      setSyncCalendarData({ code, provider: CalendarProvider.Google });
      toggleSection('calendar');
    } else {
      setSyncCalendarData({ code, provider: CalendarProvider.Outlook });
      toggleSection('calendar');
    }
  }, [location]);

  useEffect(() => {
    const meetingInfoFromUrl = extractMeetingInfoFromUrl(location.search, location.pathname);
    if (!meetingInfoFromUrl) return;
    setSyncMeetingProviderData(meetingInfoFromUrl);
    toggleSection('calendar');
  }, [location]);

  useEffect(() => {
    setLanguageOptions(getLanguageOptions(literals));
  }, [literals]);

  useEffect((): void => {
    if (!options) return;
    if (options.includes('notifications')) {
      setOpenSections({
        ...openSections,
        notifications: true,
      });
      if (options === 'notifications=off') {
        setNotificationsOptions('off');
      } else if (options === 'notifications=on') {
        setNotificationsOptions('on');
      }
    } else if (options.includes('password')) {
      setOpenSections({
        ...openSections,
        password: true,
      });
    } else if (options.includes('account')) {
      setOpenSections({
        ...openSections,
        account: true,
      });
    } else if (options.includes('language')) {
      setOpenSections({
        ...openSections,
        language: true,
      });
    } else if (options.includes('calendar')) {
      setOpenSections({
        ...openSections,
        calendar: true,
      });
    } else if (options.includes('email')) {
      setOpenSections({
        ...openSections,
        email: true,
      });
    }
  }, [options]);

  const handleDownloadClick = (): void => {
    setIsDownloading(true);
    downloadPersonalInfo(bearer).finally(() => setIsDownloading(false));
  };

  const resetForm = (): void => {
    setOldPassword('');
    setNewPassword('');
    setPasswordConfirmation('');
    setIsChangingPassword(false);
  };

  const handlePasswordChange = (event: SyntheticEvent): void => {
    event.preventDefault();
    if (newPassword !== passwordConfirmation) {
      toast(literals.user_settings_confirm_password_not_match_error_message, { type: 'error' });
      return;
    }
    setIsChangingPassword(true);
    resetPassword(bearer, oldPassword, newPassword, account.id)
      .then(() => toast(literals.user_setting_ctrl_password_changed_message, { type: 'success' }))
      .catch(() => toast(literals.change_password_error_message, { type: 'error' }))
      .finally(resetForm);
  };

  const handleDisableAccount = (): void => {
    disableAccount(bearer)
      .then(() => {
        signOut();
        toast(literals.disable_account_success_message, { type: 'success' });
      })
      .catch(() => {
        toast(literals.disable_account_error_message, { type: 'error' });
      });
  };

  const handleEmailChange = (event: SyntheticEvent): void => {
    event.preventDefault();
    if (oldEmail === newEmail) {
      toast(literals.user_settings_old_new_emails_match_error_message, { type: 'error' });
      return;
    }

    if (newEmail !== newEmailConfirmation) {
      toast(literals.user_settings_confirm_email_not_match_error_message, { type: 'error' });
      return;
    }

    setIsChangingEmail(true);
    changeAccountEmail(bearer, oldEmail, newEmail, emailPassword)
      .then(() => {
        toast(literals.user_setting_account_email_changed_message, { type: 'success' });
        setOldEmail('');
        setNewEmail('');
        setNewEmailConfirmation('');
      })
      .catch(() => toast(literals.change_email_error_message, { type: 'error' }))
      .finally(() => {
        setIsChangingEmail(false);
        setEmailPassword('');
      });
  };

  const toggleSection = (
    section: 'password' | 'account' | 'notifications' | 'language' | 'calendar' | 'email',
  ): void => {
    setOpenSections({
      ...openSections,
      [section]: !openSections[section],
    });
  };

  return (
    <Container>
      <SectionName $open={openSections.calendar} onClick={() => toggleSection('calendar')}>
        {' '}
        <ArrowIcon /> {literals.calendar_name}
      </SectionName>
      {openSections.calendar && (
        <Calendar
          syncCalendarData={syncCalendarData}
          syncMeetingProviderData={syncMeetingProviderData}
          bearer={bearer}
        />
      )}
      <Separator />
      <SectionName $open={openSections.password} onClick={() => toggleSection('password')}>
        {' '}
        <ArrowIcon /> {literals.public_singup_password}
      </SectionName>
      {openSections.password && (
        <Form onSubmit={handlePasswordChange}>
          <Label>{literals.user_settings_current_password_placeholder}</Label>
          <Input
            placeholder={literals.user_settings_current_password_placeholder}
            type="password"
            required
            value={oldPassword}
            onChange={event => setOldPassword(event.target.value)}
          />
          <Label>{`${literals.user_settings_new_password_title}`}</Label>
          <Input
            placeholder={literals.user_settings_new_password_placeholder}
            type="password"
            required
            pattern=".{8,}"
            value={newPassword}
            onChange={event => setNewPassword(event.target.value)}
          />
          <Label>{literals.user_settings_confirm_new_password_placeholder}</Label>
          <Input
            placeholder={literals.user_settings_confirm_new_password_placeholder}
            type="password"
            required
            pattern=".{8,}"
            value={passwordConfirmation}
            onChange={event => setPasswordConfirmation(event.target.value)}
          />
          <StyledButton style={{ marginTop: '2em' }} type="submit" disabled={isChangingPassword}>
            {literals.user_settings_change_password_button}
          </StyledButton>
        </Form>
      )}
      <Separator />
      <SectionName $open={openSections.notifications} onClick={() => toggleSection('notifications')}>
        {' '}
        <ArrowIcon /> {literals.user_ctrl_initializer_menu_email_notifications}
      </SectionName>
      {openSections.notifications && (
        <NotificationsTab isFetching={isFetching} bearer={bearer} account={account} options={notificationsOptions} />
      )}
      <Separator />
      <SectionName $open={openSections.language} onClick={() => toggleSection('language')}>
        {' '}
        <ArrowIcon /> {literals.menu_language}
      </SectionName>
      {openSections.language && (
        <>
          <Row style={{ alignItems: 'center' }}>
            <Line style={{ marginLeft: '1em', marginRight: '2em', whiteSpace: 'nowrap' }}>
              {literals.menu_change_language}
            </Line>
            <Dropdown
              width="10em"
              onChange={(evt: any, data: any) => {
                changeLanguage(data.value);
              }}
              options={languageOptions}
              value={account.language}
              scrolling
            />
          </Row>
        </>
      )}
      <Separator />
      <SectionName $open={openSections.account} onClick={() => toggleSection('account')}>
        {' '}
        <ArrowIcon /> {literals.profile_account_tab}
      </SectionName>
      {openSections.account && (
        <>
          <Line>{account.email}</Line>
          <Line>
            {literals.profile_account_created_on} {moment(account.registrationDate).format('MMM D, YYYY')}
          </Line>
          <SectionName $open={openSections.email} onClick={() => toggleSection('email')}>
            {''}
            <ArrowIcon /> {literals.user_settings_change_email}
          </SectionName>
          {openSections.email && (
            <Form onSubmit={handleEmailChange}>
              <Label>{literals.user_settings_current_email_placeholder}</Label>
              <Input
                placeholder={literals.user_settings_current_email_placeholder}
                type="email"
                required
                value={oldEmail}
                onChange={event => setOldEmail(event.target.value)}
              />
              <Label>{literals.user_settings_new_email_placeholder}</Label>
              <Input
                placeholder={literals.user_settings_new_email_placeholder}
                type="email"
                required
                value={newEmail}
                onChange={event => setNewEmail(event.target.value)}
              />
              <Label>{literals.user_settings_confirm_new_email_placeholder}</Label>
              <Input
                placeholder={literals.user_settings_confirm_new_email_placeholder}
                type="email"
                required
                value={newEmailConfirmation}
                onChange={event => setNewEmailConfirmation(event.target.value)}
              />
              <Label>{literals.user_settings_current_email_password_placeholder}</Label>
              <Input
                placeholder={literals.user_settings_current_email_password_placeholder}
                type="password"
                required
                value={emailPassword}
                onChange={event => setEmailPassword(event.target.value)}
              />
              <StyledButton style={{ marginTop: '2em' }} type="submit" disabled={isChangingEmail}>
                {literals.user_settings_change_email}
              </StyledButton>
            </Form>
          )}
          <Row>
            <ActionButton onClick={handleDownloadClick} disabled={isDownloading}>
              {literals.user_settings_request_personal_information_button}
            </ActionButton>
            <RedButton onClick={() => setShowDisableModal(true)}>
              {literals.user_settings_disable_account_button}
            </RedButton>
          </Row>
        </>
      )}
      <Separator />
      <Modal title={literals.profile_disable_account_modal_title} open={showDisableModal}>
        <Line style={{ textAlign: 'center' }}>{literals.user_settings_disable_account_confirm_details}</Line>
        <Line style={{ textAlign: 'center', marginTop: '1em' }}>
          {literals.user_settings_disable_account_confirm_question}
        </Line>
        <Row style={{ fontSize: '16px', justifyContent: 'center' }}>
          <ActionButton onClick={() => setShowDisableModal(false)}>{literals.confirm_cancel_button}</ActionButton>
          <RedButton onClick={handleDisableAccount}>{literals.user_settings_disable_account_button}</RedButton>
        </Row>
      </Modal>
    </Container>
  );
};

export default connect(mapStateToProps, {})(Account);
