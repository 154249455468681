import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop, centerCrop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal } from 'semantic-ui-react';
import { SecondaryButton } from 'components/styled-components/common';
import { StyledButton } from 'primitives/Button/style';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RootState } from 'StoreModel';
import { AspectRatio } from 'redux/types/enums';
import { useDebounceEffect, dataURLtoFile } from 'util/utils';
import { XButton } from '../modal-navigation';

interface ImageCropperProps extends ReturnType<typeof mapStateToProps> {
  cropShape: AspectRatio;
  circularCrop?: boolean;
  handleFileSelection: (file: File) => void;
  open: boolean;
  onClose: () => void;
  selectedFile: File | null;
  openFileSelection: () => void;
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

const CropContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  position: absolute;
  bottom: 30px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  max-height: 80%;
  overflow: hidden;

  // Target the ReactCrop elements
  .ReactCrop {
    height: 100%;

    .ReactCrop__child-wrapper {
      height: 100%;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const StyledModal = styled(Modal)<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  @media (max-width: 600px) {
    width: unset;
  }
`;

const MIN_WIDTH = 400; // Minimum width for the modal
const MIN_HEIGHT = 400; // Minimum height for the modal
const MAX_WIDTH = 700; // Maximum width for the modal
const MAX_HEIGHT = 600; // Maximum height for the modal

const mapStateToProps = (state: RootState) => ({
  literals: state.literals,
});

const ImageCropper: React.FC<ImageCropperProps> = ({
  handleFileSelection,
  cropShape,
  circularCrop,
  open,
  onClose,
  selectedFile,
  openFileSelection,
  literals,
}) => {
  const [imgSrc, setImgSrc] = useState('');
  const [image, setImage] = useState<File | null>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [size, setSize] = useState<{
    width: string;
    height: string;
  }>({
    width: `${MIN_WIDTH}px`,
    height: `${MIN_HEIGHT}px`,
  });

  useEffect(() => {
    if (selectedFile) {
      setCrop(undefined);
      setCompletedCrop(undefined);
      setImage(null);
      setImgSrc('');
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);

  const handleSave = () => {
    if (image) {
      handleFileSelection(image);
      onClose();
    }
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    const aspectRatio = width / height;
    const padding = 100;
    let newWidth = width + padding;
    let newHeight = height + padding;

    // Ensure width and height are within min and max bounds
    if (newWidth < MIN_WIDTH) newWidth = MIN_WIDTH;
    if (newWidth > MAX_WIDTH) newWidth = MAX_WIDTH;
    if (newHeight < MIN_HEIGHT) newHeight = MIN_HEIGHT;
    if (newHeight > MAX_HEIGHT) newHeight = MAX_HEIGHT;

    if (aspectRatio > 1) {
      // Landscape
      newHeight = newWidth / aspectRatio + padding;
      if (newHeight > MAX_HEIGHT) {
        newHeight = MAX_HEIGHT;
        newWidth = newHeight * aspectRatio + padding;
      }
    } else {
      // Portrait
      newWidth = newHeight * aspectRatio + padding;
      if (newWidth > MAX_WIDTH) {
        newWidth = MAX_WIDTH;
        newHeight = newWidth / aspectRatio + padding;
      }
    }

    setSize({
      width: `${newWidth}px`,
      height: `${newHeight}px`,
    });

    if (cropShape) {
      setCrop(centerAspectCrop(width, height, cropShape));
    }

    return false; // Prevent ReactCrop from performing its own setting of the crop box
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        getCroppedImg(imgRef.current, completedCrop);
      }
    },
    100,
    [completedCrop],
  );

  const getCroppedImg = (image: HTMLImageElement, crop: PixelCrop) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;
    ctx.save();

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

    ctx.restore();

    const dataUrl = canvas.toDataURL('image/jpeg', 0.5); // Adjust quality as needed

    const file = dataURLtoFile(dataUrl, 'cropped-image.jpg');

    setImage(file);
  };

  const handleCloseModal = () => {
    setImgSrc('');
    setImage(null);
    onClose();
  };

  return (
    <StyledModal
      size="large"
      width={size.width}
      height={size.height}
      open={open}
      onClose={handleCloseModal}
      closeOnDimmerClick={false}
    >
      <XButton onClick={handleCloseModal} style={{ position: 'absolute', right: '0' }}></XButton>
      <CropContainer>
        <ImageContainer>
          {!imgSrc && <p style={{ textAlign: 'center' }}>{literals.global_no_image_selected}</p>}
          {!!imgSrc && (
            <ReactCrop
              key={imgSrc}
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => setCompletedCrop(c)}
              aspect={cropShape}
              circularCrop={circularCrop}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </ReactCrop>
          )}
        </ImageContainer>
        <FooterContainer>
          <SecondaryButton onClick={openFileSelection}>{literals.global_choose_file}</SecondaryButton>
          {image && (
            <div>
              <StyledButton onClick={handleSave}>{literals.global_save_image}</StyledButton>
            </div>
          )}
        </FooterContainer>
      </CropContainer>
    </StyledModal>
  );
};

export default connect(mapStateToProps)(ImageCropper);
