import React from 'react';
import styled from 'styled-components';
import Editor from 'components/editor';

const ComposerCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .toolbar {
    border: none;
    padding: 0;
  }

  .ck.ck-editor__editable_inline {
    padding: 0;
  }
  .ck-toolbar-container {
    top: 0px !important;
    left: 0px !important;
  }
`;

const ComposerBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  textarea {
    font-size: 0.9em;
  }
`;

const EditorHeight = styled.div`
  width: 100%;

  .ck-content {
    min-height: 10em;
    background-color: ${({ theme }) => theme.colors.blue.veryLight};
    &:focus {
      background-color: white;
    }
  }
`;

const CkEditorBio = ({ bio, setBio, placeholder }) => {
  return (
    <ComposerCard>
      <ComposerBody>
        <EditorHeight>
          <Editor content={bio} setContent={setBio} placeholder={placeholder} lightToolbar />
        </EditorHeight>
      </ComposerBody>
    </ComposerCard>
  );
};

export default CkEditorBio;
