import { Epic, ofType } from 'redux-observable';
import { from } from 'rxjs';
import { filter, switchMap, concatMap, map, catchError, tap, ignoreElements } from 'rxjs/operators';
import { RootAction, RootState, Services } from 'StoreModel';
import { isActionOf } from 'typesafe-actions';
import { handleError } from './helpers';

import {
  fetchRequestAsync,
  deleteRequestAsync,
  saveRequestAsync,
  deleteRequestCommentAsync,
  fetchCommunityFilteringSkillsAsync,
  fetchProjectFilteringSkillsAsync,
  createNewRequestAsync,
  submitPendingFeedbackAsync,
  fetchRequestsPendingFeedbackAsync,
  closeRequestAsync,
  saveCoachingSessionAsync,
} from '../actions/request';
import { fetchUserRequestsPendingFeedback } from 'services/api/account';
import { of } from 'ramda';
import accountSessionBearerSelector from 'redux/selectors/account/accountSessionBearerSelector';
import HackHistory from 'routes/history';
import accountRequestsPendingFeedbackSelector from 'redux/selectors/account/accountRequestsPendingFeedbackSelector';
import { toast } from 'components/common/toast';

type EpicFunction = Epic<RootAction, RootAction, RootState, Services>;

export const fetchRequestEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchRequestAsync.request)),
    switchMap(action =>
      from(api.project.fetchRequestById(action.payload.bearer, action.payload.id)).pipe(
        map(fetchRequestAsync.success),
        catchError(error => handleError(error, fetchRequestAsync)),
      ),
    ),
  );

export const deleteRequestEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteRequestAsync.request)),
    switchMap(action =>
      from(
        api.community.deleteRequest(
          action.payload.bearer,
          action.payload.entityType,
          action.payload.entityId,
          action.payload.request,
        ),
      ).pipe(
        map(deleteRequestAsync.success),
        catchError(error => handleError(error, deleteRequestAsync)),
      ),
    ),
  );

export const closeRequestEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(closeRequestAsync.request)),
    switchMap(action =>
      from(api.community.closeRequest(action.payload.bearer, action.payload.requestId)).pipe(
        map(closeRequestAsync.success),
        catchError(error => handleError(error, closeRequestAsync)),
      ),
    ),
  );

export const saveRequestEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveRequestAsync.request)),
    switchMap(action =>
      from(api.project.saveRequest(action.payload.bearer, action.payload.projectId, action.payload.request)).pipe(
        map(saveRequestAsync.success),
        catchError(error => {
          if (error.message === 'hasAwaitingReview')
            return handleError(
              error,
              saveRequestAsync,
              'Please give feedback on your past requests before creating new requests.',
            );
          return handleError(error, saveRequestAsync);
        }),
      ),
    ),
  );

export const deleteRequestCommentEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(deleteRequestCommentAsync.request)),
    switchMap(action =>
      from(
        api.project.deleteRequestComment(
          action.payload.bearer,
          action.payload.projectId,
          action.payload.requestId,
          action.payload.commentId,
        ),
      ).pipe(
        map(deleteRequestCommentAsync.success),
        catchError(error => handleError(error, deleteRequestCommentAsync)),
      ),
    ),
  );

export const fetchCommunityFilteringSkillsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchCommunityFilteringSkillsAsync.request)),
    switchMap(action =>
      from(api.community.fetchCommunityFilteringSkills(action.payload.bearer, action.payload.communityId)).pipe(
        map(fetchCommunityFilteringSkillsAsync.success),
        catchError(error => handleError(error, fetchCommunityFilteringSkillsAsync)),
      ),
    ),
  );

export const fetchProjetFilteringSkillsEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(fetchProjectFilteringSkillsAsync.request)),
    switchMap(action =>
      from(
        api.project.fetchProjectFilteringSkills(
          action.payload.bearer,
          action.payload.projectId,
          action.payload.communityId,
        ),
      ).pipe(
        map(fetchProjectFilteringSkillsAsync.success),
        catchError(error => handleError(error, fetchProjectFilteringSkillsAsync)),
      ),
    ),
  );

export const createNewRequestRequestEpic: EpicFunction = (action, state) =>
  action.pipe(
    ofType(createNewRequestAsync.request),
    map(payload => payload.payload),
    map((payload: { projectId: number; isProjectAdmin?: boolean; replaceLocation?: boolean }) => {
      const { projectId, isProjectAdmin, replaceLocation } = payload;
      const pendingRequests: any[] = accountRequestsPendingFeedbackSelector(state.value);
      const pendingRequestsForProject = pendingRequests.find(req => req.projectId === projectId);

      return pendingRequestsForProject
        ? createNewRequestAsync.failure('Something went wrong')
        : createNewRequestAsync.success({ projectId, isProjectAdmin, replaceLocation });
    }),
    //     map(payload => Number(payload.payload)),
    // switchMap((projectId: number) =>
    //   interval(100).pipe(
    //     filter(() => {
    //       const proj = getProjectById(accountProjectsSelector(state.value), projectId);
    //       return typeof proj !== 'undefined' && typeof proj.hasPendingRequestFeedback !== 'undefined';
    //     }),
    //     first(),
    //     map(() => getProjectById(accountProjectsSelector(state.value), projectId)),
    //   ),
    // ),
    // concatMap((project: Project) => {
    //   const latestProject = getProjectById(accountProjectsSelector(state.value), project.id);
    //   // return accountRequestsPendingFeedbackSelector(state.value).length
    //   return of(
    //     latestProject.hasPendingRequestFeedback
    //       ? createNewRequestAsync.failure(project)
    //       : createNewRequestAsync.success(project),
    //   );
    // }),
  );

export const createNewRequestSuccessEpic: EpicFunction = (action, state) =>
  action.pipe(
    ofType(createNewRequestAsync.success),
    map(payload => payload.payload),
    tap((payload: { projectId: number; isProjectAdmin?: boolean; replaceLocation?: boolean }) => {
      const { projectId, isProjectAdmin, replaceLocation } = payload;
      const history = HackHistory().get();
      const requestPath = `/project/${projectId}/request/new`;
      // when creating requests from community, don't show request/coaching modal
      const notFromCommunity = isProjectAdmin && !replaceLocation;
      const newLocation = notFromCommunity ? history.location.pathname + history.location.search : requestPath;

      // Preserve existing location.state
      const existingState = history.location.state || {};

      // Merge existing state with new state
      const newState = {
        ...existingState,
        ...(notFromCommunity ? { addRequestModal: true } : {}),
      };

      history[replaceLocation || notFromCommunity ? 'replace' : 'push'](newLocation, newState);
    }),
    ignoreElements(),
  );

export const createNewRequestFailureEpic: EpicFunction = (action, state) =>
  action.pipe(
    ofType(createNewRequestAsync.failure),
    map(payload => payload.payload),
    map(submitPendingFeedbackAsync.request),
  );

export const submitPendingFeedbackRequestEpic: EpicFunction = (action, state) =>
  action.pipe(
    ofType(submitPendingFeedbackAsync.request),
    map(() => fetchRequestsPendingFeedbackAsync.request()),
  );

export const fetchRequestsPendingFeedbackRequest: EpicFunction = (action, state) =>
  action.pipe(
    ofType(fetchRequestsPendingFeedbackAsync.request),
    map(payload => payload.payload),
    filter(() => !!accountSessionBearerSelector(state.value).length === true),
    switchMap(() => from(fetchUserRequestsPendingFeedback(accountSessionBearerSelector(state.value)))),
    concatMap(out => of(fetchRequestsPendingFeedbackAsync.success(out))),
  );

export const saveCoachingSessionEpic: EpicFunction = (action$, state$, { api }) =>
  action$.pipe(
    filter(isActionOf(saveCoachingSessionAsync.request)),
    switchMap(action =>
      from(api.project.saveCoachingSession(action.payload.bearer, action.payload.coachingSession)).pipe(
        map(saveCoachingSessionAsync.success),
        tap(action => {
          const { id, projectId } = action.payload.coachingSession;
          const link = `/project/${projectId}/request/${id}`;
          const history = HackHistory().get();
          toast(state$.value?.literals?.coaching_session_created_successfully || 'Session created successfully', {
            type: 'success',
          });
          history.push(link);
        }),
        catchError(error => handleError(error, saveCoachingSessionAsync)),
      ),
    ),
  );

export default {
  fetchRequestsPendingFeedbackRequest,
  submitPendingFeedbackRequestEpic,
  createNewRequestFailureEpic,
  createNewRequestSuccessEpic,
  createNewRequestRequestEpic,
  fetchRequestEpic,
  deleteRequestEpic,
  closeRequestEpic,
  saveRequestEpic,
  deleteRequestCommentEpic,
  fetchCommunityFilteringSkillsEpic,
  fetchProjetFilteringSkillsEpic,
  saveCoachingSessionEpic,
};
